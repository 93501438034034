import { Campaign, CustomField } from "@/types/djangio"
import moment from "moment"
import { replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions"

export const replaceQuestionPlaceholder = (key: string, value: string, text: string) => {
    const capitalizedPlaceholder = `{{${key}_capitalize}}`

    if (text.includes(capitalizedPlaceholder)) {
        const capitalizedValue = `${value.charAt(0).toUpperCase()}${value.slice(1).toLowerCase()}`
        return text.replaceAll(capitalizedPlaceholder, capitalizedValue)
    }

    return text.replaceAll(`{{${key}}}`, value)
}

export const formatDateAnswer = (date: string) => {
    if (!date) return ""
    return moment(new Date(date)).format("MMM Do, YYYY")
}

export const formatMultipleOptionAnswer = (options: string[], question: CustomField) => {
    if (!Array.isArray(options)) return ""

    const values = options.map((opt) => question.options_new[opt].value).filter(Boolean)
    const isMoreThanOneSelected = values.length > 1
    return isMoreThanOneSelected
        ? `${values.slice(0, -1).join(", ")} and ${values[values.length - 1]}`
        : values[0] ?? ""
}

export const formatMessage = (message: Record<string, unknown>, isPreviewing: boolean) => {
    return isPreviewing ? { formatted: message, raw: message } : message
}

export function replaceQuestionsPlaceholders(
    text: string,
    placeholders: Record<string, any>,
    questions: CustomField[],
) {
    const questionsIndexedBySlug: Record<string, CustomField> = Object.fromEntries(
        questions.map((question) => [question.slug, question]),
    )
    return Object.entries(placeholders).reduce((text, [key, value]) => {
        let output

        const question = questionsIndexedBySlug[key]
        if (!question) {
            output = value?.toString() ?? ""
            return replaceQuestionPlaceholder(key, output, text)
        }

        switch (question.tag_type) {
            case DjangIO.app.person.types.TagType.single_option_list.value:
                output = question.options_new[value]?.value ?? ""
                break
            case DjangIO.app.person.types.TagType.multi_options_list.value:
                output = formatMultipleOptionAnswer(value ?? [], question)
                break
            case DjangIO.app.person.types.TagType.boolean.value:
                output = value === true ? "Yes" : value === false ? "No" : ""
                break
            case DjangIO.app.person.types.TagType.date.value:
                output = formatDateAnswer(value)
                break
            default:
                output = value?.toString() ?? ""
        }

        return replaceQuestionPlaceholder(key, output, text)
    }, text)
}

export function formatMessagePlaceholdersWithQuestions(
    messages: Record<string, any>,
    placeholders: Record<string, any>,
    questions: CustomField[],
) {
    const fields = ["subject", "content", "preBody", "postBody"]
    const formatMessage = (message: Record<string, string>) =>
        fields.reduce(
            (acc, field) => {
                acc[field] = replaceQuestionsPlaceholders(message[field] || "", placeholders, questions)
                return acc
            },
            {} as Record<string, string>,
        )
    return Object.entries(messages || {}).reduce((acc, [id, message]: [string, any]) => {
        if (!message?.formatted || !message?.raw) {
            return acc
        }
        return {
            ...acc,
            [id]: {
                formatted: formatMessage(message.formatted),
                raw: formatMessage(message.raw),
                advocate_editing_permission: message.advocate_editing_permission,
            },
        }
    }, {})
}

export const formatMessagePlaceholdersWithQuestionsUsingAI = async (
    messages: Record<string, any>,
    answers: Record<string, any>,
    campaign: Campaign,
) => {
    const groupId = Object.keys(messages)[0]
    const message = messages[groupId]
    const payload = {
        answers: answers,
        campaignId: campaign.id,
        ...campaign.ai_options,
    }

    const response = await DjangIO.app.grassroots.campaign.models.GrassrootsCampaign.objects
        .use_action("replace_message_with_AI_answers")
        .update(payload)
        .PATCH()

    const reworkedMessages = response?.data
    const reworkedMessage = reworkedMessages?.extra_data?.[groupId]

    if (message?.is_custom) {
        return {
            [groupId]: {
                subject: replaceEditablePlaceholders(reworkedMessage?.subject ?? ""),
                preBody: replaceEditablePlaceholders(reworkedMessage?.fixed_pre_message ?? ""),
                body: replaceEditablePlaceholders(reworkedMessage?.bodies?.[0] ?? ""),
                postBody: replaceEditablePlaceholders(reworkedMessage?.fixed_post_message ?? ""),
                advocate_editing_permission: message.advocate_editing_permission,
                is_custom: true,
            },
        }
    }

    return {
        [groupId]: {
            ...message,
            ...reworkedMessage,
        },
    }
}

export const formatMessageWithAnswers = async (
    messages: Record<string, any>,
    answers: Record<string, any>,
    questions: CustomField[],
    campaign: Campaign,
) => {
    if (campaign.should_rework_with_ai)
        return await formatMessagePlaceholdersWithQuestionsUsingAI(messages, answers, campaign).catch(() =>
            formatMessagePlaceholdersWithQuestions(messages, answers, questions),
        )
    return formatMessagePlaceholdersWithQuestions(messages, answers, questions)
}
