import styled, { css } from "styled-components"

import * as colors from "Components/colors"
import * as rules from "Components/rules"

export const WidgetFooter = styled.div`
    align-items: center;
    color: ${colors.Gray9};
    display: flex;
    flex-shrink: 0;
    // needed to match the Icon to the exact size of the mock
    font-size: 20px;
    margin: 10px 0 10px 16px;
`

export const WidgetFooterText = styled.span`
    ${rules.SmallText}
    margin-left: 5px;
    width: 100%;
`

export const WidgetHeaderTitle = styled.div`
    ${rules.MediumTextBoldWeight}
    // https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#CSS
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    width: 100%;
`

export const WidgetHeaderIcons = styled.div`
    flex-shrink: 0;
    pointer-events: all;
    z-index: 1;
    color: ${props => props.gradientHeader ? colors.White : colors.violet7};

    ${props => (!props.headerTitle && !props.editing) && css`
        visibility: hidden;
    `}

    svg {
        cursor: pointer;
    }

    // override the HyperlinkColor that is applied by Anchor
    a svg {
        color: ${colors.violet7};
    }

    & > *:not(:last-child) {
        margin-right: 11px;
    }
`

export const WidgetHeader = styled.div`
    align-items: center;
    border-radius: 4px 4px 0 0;
    color: ${colors.gray9};
    display: flex;
    flex-shrink: 0;
    height: 48px;
    justify-content: space-between;
    overflow: auto;
    padding: 0 14px 0 16px;

    ${props => (!props.headerTitle && !props.editing) && css`
        ${WidgetHeaderIcons} {
            visibility: initial;
        }
    `}

    ${props => (!props.headerTitle) && css`
        background: unset;
        border: unset;
        justify-content: flex-end;
    `}

    ${props => (props.noHeader) && css`
        ${props => (props.gradientHeader) && css`
            background: linear-gradient(to right, rgba(48,62,96,-0.7) 0%, ${colors.QuorumBlue} 100%);
            color: ${colors.White};
            height: 50px;
            padding: unset;
            padding-right: 14px;
        `}
        ${props => (props.gradientHeader && props.editing) && css`
            padding-right: 64px;
        `}
        border-radius: 4px;
        pointer-events: none;
        position: absolute;
        width: 100%;
    `}
`

export const WidgetResizeIcon = styled.div`
    bottom: 18px;
    color: ${colors.violet5};
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    right: 17px;
    z-index: 1;

    ${props => (props.gradientHeader) && css`
        bottom: 10px;
        color: ${colors.White};
    `}
`

export const WidgetDescription = styled.span`
    background-color: white;
    margin: 15px 0 8px;
    padding: 0px 17px;
    word-break: break-word;
    ${rules.SmallText}
    ${props => (props.descriptionFull) && css`
        height: 100%;
    `}
`

export const WidgetContent = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;

    ${props => (!props.widgetContentIsLoading) && css`
        flex-grow: 1;
    `}
`

export const WidgetFallback = styled.div`
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 20px;
    justify-content: center;
`

export const WidgetEmpty = styled.div`
    align-items: center;
    color: ${colors.QuorumGrey3};
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 0 60px;
    ${rules.MediumText}
`

export const Widget = styled.div`
    ${props => (
        // do not display the default border in:
        // QuickLink Widget
        (!props.noBorder) && css`
            border: 1px solid ${colors.gray2};
            height: inherit;
        `
    )}
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;

    ${props => (
        // if editing, do not display the default border in:
        // QuickLink Widget
        props.editing &&
        !props.gradientHeader
    ) && css`
        border-color: ${colors.gray2};
    `}

    ${props => (
        // if we are not in editing mode,
        // the widget is not a quicklink,
        // and it has no header,
        // hide the icons until we hover over the widget
        !props.editing &&
        !props.gradientHeader &&
        props.noHeader
    ) && css`
        ${WidgetHeaderIcons} {
            display: none;
        }
        &:hover {
            ${WidgetHeaderIcons} {
                display: unset;
            }
        }
    `}
`

export const ForbiddenLayer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(6px);
    z-index: 1;
    gap: 1.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    padding: 1rem;
    color: ${colors.gray7};
`

export const ForbiddenIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    background-color: ${colors.red1};

    svg {
        color: ${colors.red7};
    }
`
