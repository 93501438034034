import { useQuery } from "@tanstack/react-query"
import { api } from "@/api"
import { Campaign, CustomField } from "@/types/djangio"
import { replaceEditablePlaceholders } from "QuorumGrassroots/helperFunctions.js"
import { getCSRF } from "QuorumGrassroots/campaign-forms/helpers"
import { Address } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { formatMessageWithAnswers } from "./campaign-forms/components/PersonalizedMessages/components/SendMessageContainer.helper"

export const useMultiActionChildren = (parentId: string) =>
    useQuery<{ objects: Campaign[] }>({
        queryKey: ["multi-action", parentId],
        queryFn: fetchCampaign,
        enabled: Boolean(parentId),
        staleTime: Infinity,
        select: convertMessages,
    })

function convertMessages(data: { objects: Campaign[] }) {
    const result = data.objects.map((campaign) => {
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_member.value) {
            return convertMessageGroupsCampaign(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.comment_on_regulation.value) {
            return convertRegulationMessages(campaign)
        }
        if (campaign.campaign_type === DjangIO.app.grassroots.campaign.types.CampaignType.write_white_house.value) {
            return convertWriteWhiteHouseMessages(campaign)
        }
        return campaign
    }) as Campaign[]
    return { objects: result }
}

export const convertMessageGroupsCampaign = (campaign: Campaign) => {
    const messages = {}
    const targets = {}
    const existing_target_ids = {}
    if (isFeatureEnabled("ff_ngg_message_preview")) {
        Object.entries(campaign.extra_message_data).forEach(([group_id, message]) => {
            messages[group_id] = {
                raw: {
                    subject: replaceEditablePlaceholders(message.raw.subject),
                    content: replaceEditablePlaceholders(message.raw.content),
                    preBody: replaceEditablePlaceholders(message.raw.fixed_pre_message),
                    postBody: replaceEditablePlaceholders(message.raw.fixed_post_message),
                },
                formatted: {
                    subject: message.formatted.subject,
                    content: message.formatted.content,
                    preBody: replaceEditablePlaceholders(message.raw.fixed_pre_message),
                    postBody: replaceEditablePlaceholders(message.raw.fixed_post_message),
                },
            }
        })
        for (const message of campaign.messages as WriteALetterMessages) {
            // Here we want to make sure a legislator receives only one message even if they are in multiple groups
            // We are adding it to the first group they are in - similar to GET_CAMPAIGN_MESSAGES_SUCCESS for Redux
            if (message.target.id in existing_target_ids) {
                continue
            }
            existing_target_ids[message.target.id] = true

            messages[message.group.id] = {
                ...messages[message.group.id],
                advocate_editing_permission: message.group.advocate_editing_permission,
                id: message.group.id,
                name: message.group.name,
            }

            if (message.target.is_custom)
                messages[message.group.id] = {
                    ...messages[message.group.id],
                    subject: replaceEditablePlaceholders(message.subject),
                    preBody: replaceEditablePlaceholders(message.fixed_pre_body),
                    body: replaceEditablePlaceholders(message.bodies[0]),
                    postBody: replaceEditablePlaceholders(message.fixed_post_body),
                    is_custom: true,
                }

            if (!targets[message.group.id]) targets[message.group.id] = []
            targets[message.group.id].push({
                imageUrl: message.target.image_url,
                label: message.target.name,
                value: message.target.id,
                targeted: true,
                ...(message.target.is_custom && { is_custom: true }),
            })
        }
    } else {
        for (const message of campaign.messages as WriteALetterMessages) {
            // Here we want to make sure a legislator receives only one message even if they are in multiple groups
            // We are adding it to the first group they are in - similar to GET_CAMPAIGN_MESSAGES_SUCCESS for Redux
            if (message.target.id in existing_target_ids) {
                continue
            }
            existing_target_ids[message.target.id] = true

            messages[message.group.id] = {
                subject: replaceEditablePlaceholders(message.subject),
                preBody: replaceEditablePlaceholders(message.fixed_pre_body),
                body: replaceEditablePlaceholders(message.bodies[0]),
                postBody: replaceEditablePlaceholders(message.fixed_post_body),
                advocate_editing_permission: message.group.advocate_editing_permission,
                id: message.group.id,
                name: message.group.name,
            }

            if (!targets[message.group.id]) targets[message.group.id] = []
            targets[message.group.id].push({
                imageUrl: message.target.image_url,
                label: message.target.name,
                value: message.target.id,
                targeted: true,
                ...(message.target.is_custom && { is_custom: true }),
            })
        }
    }
    return { ...campaign, messages, targets }
}

export const convertRegulationMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
        },
    }
    return { ...campaign, messages }
}

export const convertWriteWhiteHouseMessages = (campaign: Campaign) => {
    const messages = {
        0: {
            body: replaceEditablePlaceholders(campaign.messages[0].bodies[0]),
            preBody: replaceEditablePlaceholders(campaign.messages[0].fixed_pre_body),
            postBody: replaceEditablePlaceholders(campaign.messages[0].fixed_post_body),
            subject: replaceEditablePlaceholders(campaign.messages[0].subject),
        },
    }
    return { ...campaign, messages }
}

const fetchCampaign = async ({ queryKey }) => {
    const parentId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_multi_action_children",
        params: {
            parent_campaign_id: parentId,
            decode_enums: "false",
            dehydrate_extra: "message_groups",
        },
    })
    return response?.json?.()
}

export const usePersonalizedCampaignGetCampaignMessages = (campaignId: string, enabled = true) =>
    useQuery({
        queryKey: ["get_campaign_messages_personalized_campaign", campaignId],
        queryFn: fetchSimpleCampaignMessages,
        enabled: enabled,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        select: convertMessageGroupsCampaign,
    })

export const useReworkMessageWithAI = (
    messageData: ReturnType<typeof convertMessageGroupsCampaign>,
    answers: Record<string, any>,
    questions: CustomField[],
    campaign: Campaign,
    enabled = true,
) =>
    useQuery({
        queryKey: ["parsedMessages", messageData, answers, questions, campaign, messageData?.messages],
        queryFn: async () => {
            if (!messageData) return {}
            return formatMessageWithAnswers(messageData?.messages, answers, questions, campaign)
        },
        enabled: enabled,
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

export const useSimpleGetCampaignMessages = (campaignId: string) =>
    useQuery({
        queryKey: ["get_campaign_messages", campaignId],
        queryFn: fetchSimpleCampaignMessages,
        enabled: true,
        staleTime: Infinity,
    })

const fetchSimpleCampaignMessages = async ({ queryKey }) => {
    const campaignId = queryKey[1]
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_campaign_messages",
        params: { campaign_id: campaignId, decode_enums: "false", count: "false" },
    })
    return response.json()
}

export const getCampaignUrlsFromId = async (campaignIds: string[], missingRawUrls: string[]) => {
    return await api
        .get({
            model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
            action: "get_campaign_ac_urls_from_campaign_placeholder_ids",
            params: {
                ids: campaignIds.join(","),
                urls: missingRawUrls.join(","),
                decode_enums: "false",
            },
        })
        .then((response) => response.json())
        .catch((error) => ({ error }))
}

export const postGrassrootsAction = async (body) => {
    return await api.post(
        {
            model: DjangIO.app.grassroots.models.GrassrootsSupporterAction,
            params: "decode_enums=false&count=false&exclude=%7B%22interaction_type%22%3A34%7D&archived=false",
        },
        body,
        {
            headers: {
                "X-Csrftoken": getCSRF(),
            },
        },
    )
}

export const patchGrassrootsAction = async (actions: object) => {
    return await api.patch(
        {
            model: DjangIO.app.grassroots.models.GrassrootsSupporterAction,
            params: "decode_enums=false&count=false&exclude=%7B%22interaction_type%22%3A34%7D&archived=false",
        },
        actions,
        {
            headers: {
                "X-Csrftoken": getCSRF(),
            },
        },
    )
}

export const updateSupporterHasParticipatedInCampaigns = async () => {
    const participatedCampaigns = window.userdata.participated_campaigns
    const hasEverParticipated = window.userdata.has_participated_in_any_campaign_dn

    if (participatedCampaigns?.length === 0 || !hasEverParticipated) {
        return await api.patch(
            {
                model: DjangIO.app.grassroots.models.Supporter,
                action: "patch_supporter_has_participated_in_any_campaign_dn",
            },
            { has_participated_in_any_campaign_dn: true, supporterId: window.userdata.id },
            {
                throwOnNotOk: false,
                headers: {
                    "X-Csrftoken": getCSRF(),
                },
            },
        )
    }
}

export const fetchOfficialsByAddress = async (
    campaignId: string,
    address: string,
    email: string,
    addressDict: Address | null,
) => {
    const params = {
        campaign_id: campaignId,
        address,
        email,
    }

    if (addressDict) {
        params["address_dict"] = JSON.stringify(addressDict)
    }

    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "get_targets_by_address",
        params,
    })

    return response?.json?.()
}

interface Payload {
    objects: Array<{
        message_group_id: string
        subject: string
        text: string
        campaign: string
        supporter_action_type: number
        person: string
        supporter: string
        organization: string
        action_center: string
        points_earned: number
    }>
}
